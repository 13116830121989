<template>
  <div class="contentInner">
    <h1><span>MPU Completed</span><br />Unterstützung ohne Grenzen</h1>
    <p>Bei MPU Completed bieten wir Ihnen professionelle Unterstützung bei Ihrer MPU-Vorbereitung an, unabhängig davon, wo Sie sich befinden. Mit Standorten in Berlin, Zürich und Oberwil-Lieli stehen wir Ihnen lokal zur Verfügung. Allerdings ist es nicht zwingend erforderlich, eines unserer Büros persönlich aufzusuchen, da unsere umfassende MPU-Beratung auch komplett online durchgeführt werden kann. Unsere Experten sind darauf spezialisiert, Sie virtuell durch den gesamten Prozess zu begleiten und Ihnen die bestmögliche Unterstützung zu bieten – bequem und flexibel von jedem Ort aus.</p>
  </div>
    <div class="contentRow">
      <div class="contentCol3 ">
        <h3>Berlin</h3>
        <p>Alt Tegel</p>
        <p>13507 Berlin</p>
        <button @click.prevent="mainNavigation(kontakt)" style="margin-top:20px;">Terminanfrage</button>
      </div>
      <div class="contentCol3 ">
        <h3>Oberwil-Lieli</h3>
        <p>Freiamt</p>
        <p>8966 Oberwil-Lieli</p>
        <button @click.prevent="mainNavigation(kontakt)" style="margin-top:20px;">Terminanfrage</button>
      </div>
      <div class="contentCol3 ">
        <h3>Zürich</h3>
        <p>Römerhof</p>
        <p>8032 Zürich</p>
        <button @click.prevent="mainNavigation(kontakt)" style="margin-top:20px;">Terminanfrage</button>
      </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      mainNavigation:{Type: Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        kontakt:{
          name:"Kontakt",
          url:"/kontakt",
        },
        angebot:{
          name:"Angebot",
          url:"/angebot",
        }
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>