<template>
    <div class="contentInner ">
       <h1><span>MPU Completed</span><br />Ihr Weg zur MPU</h1>
       <p>Wir verstehen, dass die Vorbereitung auf eine Medizinisch-Psychologische Untersuchung (MPU) eine wichtige und auch herausfordernde Phase darstellt. Unser Ziel ist es, Sie auf diesem Weg bestmöglich zu unterstützen und Ihnen zu helfen, Ihre Fahrerlaubnis zurückzuerlangen. Unsere Beratung basiert auf einem strukturierten Ablauf, der individuell auf Ihre Bedürfnisse zugeschnitten ist.</p>
    </div>
    <div class="contentStepsWrapper">
      <div class="contentSteps ">
        <div class="contentStepsInner">
          <div class="contentStepsBullet"><p>1</p></div>
          <div class="contentSteptText">
            <h2>Kostenloses Erstgespräch</h2>
            <p>In einem persönlichen Gespräch oder telefonischen Austausch lernen wir uns kennen und besprechen Ihre Situation. Diese Erstberatung ist für Sie kostenlos und dient dazu, Ihr Anliegen zu verstehen und Ihnen einen ersten Einblick in unsere Arbeitsweise zu geben.</p>
          </div>
        </div>
        <div class="contentStepsInner">
          <div class="contentStepsBullet"><p>2</p></div>
          <div class="contentSteptText">
            <h2>Analyse Ihrer Situation</h2>
            <p>Nach der Erstberatung führen wir eine gründliche Analyse Ihrer individuellen Situation durch. Wir betrachten dabei nicht nur die Umstände, die zur MPU geführt haben, sondern auch Ihre persönlichen Stärken und Ressourcen. Diese Analyse bildet die Grundlage für die weitere Planung unserer Beratung.</p>
          </div>
        </div>
        <div class="contentStepsInner">
          <div class="contentStepsBullet"><p>3</p></div>
          <div class="contentSteptText">
            <h2>Entwicklung eines maßgeschneiderten Beratungskonzepts</h2>
            <p>Basierend auf der Analyse erstellen wir ein maßgeschneidertes Beratungskonzept, das genau auf Ihre Bedürfnisse abgestimmt ist. Dies umfasst sowohl die inhaltliche Ausrichtung der Beratung als auch den zeitlichen Rahmen und die Methoden, die wir verwenden werden.</p>
          </div>
        </div>
        <div class="contentStepsInner">
          <div class="contentStepsBullet"><p>4</p></div>
          <div class="contentSteptText">
            <h2>Durchführung der Beratungssitzungen</h2>
            <p>In regelmäßigen Beratungssitzungen setzen wir gemeinsam das entwickelte Konzept um. Dabei stehen der Austausch, die Reflexion und die praktische Umsetzung von Verhaltensänderungen im Mittelpunkt. Unsere erfahrenen Verkehrspsychologen begleiten Sie einfühlsam und professionell durch diesen Prozess.</p>
          </div>
        </div>
        <div class="contentStepsInner">
          <div class="contentStepsBullet"><p>5</p></div>
          <div class="contentSteptText">
            <h2>Vorbereitung auf die MPU</h2>
            <p>Im Rahmen unserer Beratung bereiten wir Sie gezielt auf die MPU vor. Wir helfen Ihnen dabei, sich optimal auf die Untersuchungssituation einzustellen, Ihre persönlichen Fortschritte zu reflektieren und Ihre Chancen auf ein positives Ergebnis zu maximieren.</p>
          </div>
        </div>
        <div class="contentStepsInner">
          <div class="contentStepsBullet"><p>6</p></div>
          <div class="contentSteptText">
            <h2>Nachbetreuung und Unterstützung</h2>
            <p>Auch nach Abschluss der Beratung stehen wir Ihnen weiterhin zur Seite. Wir bieten Ihnen Nachbetreuung und Unterstützung an, um sicherzustellen, dass Sie auch nach der MPU erfolgreich Ihren Weg zurück in den Straßenverkehr finden.</p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>