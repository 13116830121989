<template>
     <div class="contentInner ">
      <h1><span>Zurück zum Führerschein</span><br />Entdecken Sie Ihren Weg zurück auf die Straße beim kostenlosen Erstgespräch</h1>
      <p>Beim kostenlosen Erstgespräch analysiere ich Ihren Fall und erarbeite gemeinsam mit Ihnen einen Plan, wie Sie möglichst schnell wieder mobil sind. Melden Sie sich jetzt an und sichern Sie sich Ihren Termin!</p>
    </div>
    <div  class="contentRow " style="margin-bottom:0px">
        <div class="contentColForm" style="width:100%">
          <h2 style="text-align: center;">Jetzt unverbindlich anmelden für Ihr kostenloses Erstgespräch</h2>
          
          <div class="flexForm">
            <div>
              <label for="name">Name</label>
              <input type="text" id="name" class="formTxt"  v-model="name">
            </div>
            <div>
              <label for="email">E-Mail</label>
              <input type="text" id="email" class="formTxt"  v-model="email">
            </div>
            <div>
              <label for="telefon">Telefon</label>
              <input type="text" id="telefon" class="formTxt"  v-model="telefon">
            </div>
          </div>

          <div>
            <button @click="submitForm">Jetzt Termin vereinbaren</button>
          </div>
        </div>
      </div>
      <div class="contentInner ">
       
      <h1><span>Oder rufen Sie mich an unter</span><br />+49 178 696 68 87</h1>
      <div class="portrait">
          <img src="../assets/portrait.jpg">
        </div>
        <h2>Dr. B.L. von Lindenberger<br>Psychologische Psychotherapeutin</h2>
      <p>Nehmen Sie die Kontrolle über Ihre Fahrerlaubnis zurück – jetzt handeln!</p>
    </div>


    <div class="formSend" v-if="formStatus">
        <div class="formSendInner">
          <h2>Vielen Dank für Ihr Vertrauen</h2>
          <p>Wir haben Ihre Anfrage erhalten und werden uns so schnell wie möglich bei Ihnen melden. Ihr Anliegen ist uns wichtig, und wir werden unser Bestes tun, um Ihnen weiterzuhelfen. Sollten Sie dringende Fragen haben oder sofortige Unterstützung benötigen, zögern Sie nicht, uns telefonisch zu kontaktieren.</p>
          <p> &nbsp;</p>
          <button @click="formStatus=false">Schliessen</button>
        </div>
      </div>

</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        name:"",
        email:"",
        telefon:"",
        formStatus:false,
        WEB3FORMS_ACCESS_KEY:"f3dae105-64b2-46fe-b30a-d9f100454269",
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async submitForm() {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: this.WEB3FORMS_ACCESS_KEY,
            titel:"Kostenloses Erstgespräch vereinbaren",
            name: this.name,
            telefon: this.telefon, 
            email: this.email,            
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(result);
          this.formStatus = true;
          this.name = "";
          this.email = "";
          this.telefon ="";
        }
      },

    },
    
  };
</script>
<style lang="scss">
</style>