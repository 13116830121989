<template>
  <div class="mainContainer ">
    <MainNavigation :mainNav="mainNav" :mainNavigation="mainNavigation" :mobileNavOpen="mobileNavOpen" :mobileNavFunc="mobileNavFunc" />
    <div class="contentContainer ">  
       <router-view :mainNavigation="mainNavigation" />
    </div>
    <Transition name="fade">
      <div class="loader" v-if="loading">
        <div class="loaderInner">
          <img src="./assets/Rolling-1s-200px.gif" >
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import MainNavigation from '@/components/MainNavigation.vue'
export default {
  name: '${COMPONENT_NAME}',
  components: {
    MainNavigation
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      mobileNavOpen:false,
      loading:false,
      routerDisable:true,
      mainNav:{
      active:"",
      navigation:[
        {
          name:"home",
          url:"/",
        },
        {
          name:"Ablauf",
          url:"/ablauf",
        },
        {
          name:"Über uns",
          url:"/about",
        },
        {
          name:"Erstgespräch",
          url:"/erstberatung",
        },
        {
          name:"Standorte",
          url:"/standorte",
        },
        {
          name:"Kontakt",
          url:"/kontakt",
        }
      ]
      }
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {
    '$route'() {
    console.log('router has changed')
    this.mainNav.active = this.$router.currentRoute._value.fullPath;
  }
  },
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
   created() {
    
   },
  // beforeMount() {},
  // render(h) { return h(); },
   mounted() {
    //console.log(window.location.href)
    //const route = useRoute();
    console.log(this.$router.currentRoute._value.fullPath);
    this.mainNav.active = this.$router.currentRoute._value.fullPath;
   },
  // beforeUpdate() {},
   updated() {
    
   },
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    mainNavigation(item){
      this.loading = true;
      this.mainNav.active = item.url;     
      this.mobileNavOpen = false;
      setTimeout(() => this.transition(item.url), 2000);
      
    },
    transition(url){
      this.$router.push(url);
      this.loading  = false;
      
    },
    mobileNavFunc(){
      window.scrollTo(0,1);
      console.log("MOBILENAV");
      this.mobileNavOpen = !this.mobileNavOpen;
      
    }
  },
};
</script>
<style lang="scss">
@import 'scss/app.scss';

.fade-enter-active,
.fade-leave-active {
  transition: width 0.5s ease;
  overflow: hidden;

}

.fade-enter-from,
.fade-leave-to {
  width: 0vw;
  right:0;

}

a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

</style>
