<template>
    <div class="contentInner ">
      <h1><span>MPU Completed</span><br />Erfahrene Verkehrspsychologen<br />für Ihren MPU-Erfolg</h1>
      <p>MPU Completed besteht aus einem hochqualifizierten Team von Verkehrspsychologen, die sich darauf spezialisiert haben, Menschen auf dem Weg zur erfolgreichen MPU zu unterstützen. Mit diversen Weiterbildungen und langjähriger Erfahrung in der Verkehrspsychologie sind wir Ihr verlässlicher Partner, wenn es darum geht, Ihre Fahrerlaubnis zurückzuerlangen.</p>
      <p>Unsere Berater verfügen über ein breites Spektrum an Fachkenntnissen und sind stets bestrebt, ihr Wissen zu erweitern und sich auf dem neuesten Stand zu halten. Dadurch können wir sicherstellen, dass unsere Beratung nicht nur auf dem aktuellen Stand der Wissenschaft basiert, sondern auch die neuesten gesetzlichen Anforderungen berücksichtigt.</p>
      <p>Unser oberstes Ziel ist es, Ihnen nicht nur dabei zu helfen, die MPU erfolgreich zu bestehen, sondern auch langfristige Verhaltensänderungen zu fördern, die Ihre Sicherheit im Straßenverkehr nachhaltig verbessern. Mit unserer hohen Erfolgsquote und unserem Engagement für kontinuierliche Weiterentwicklung stehen wir Ihnen einfühlsam und kompetent zur Seite, um Ihren Weg zurück auf die Straße zu ebnen.</p>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>