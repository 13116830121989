<template>
    <div class="contentInner ">
      <h1>Genetischer Wandel: Die Epigenetik als Königin unserer Entwicklung</h1>
      <p>Die Epigenetik, vergleichbar mit der Evolution einer Biene im Bienenstock, reagiert auf äußere Einflüsse und gestaltet unsere genetische Entwicklung. Wie Arbeitsbienen im Bienenstock ihre Aufgaben anpassen, moduliert die Epigenetik unsere genetischen Prozesse. Eine gezielte epigenetische Anpassung kann, wie bei der Königin, zu erhöhter Vitalität führen. Erforschen Sie die faszinierende Welt der Epigenetik in der Dokumentation von SRF für Einblicke in diese revolutionäre Wissenschaft.</p>
    </div>
    <div class="contentInner ">
      <iframe width="100%" height="485" src="https://www.youtube.com/embed/I2dEk2_lGAM?si=fj0wB8vxJy5J2FQt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>