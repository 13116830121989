<template>
     <div class="navContainer desktopOnly">
      <div class="logoContainer ">
        <img src="../assets/logo_neg.svg">
      </div>
      <nav>
        <ul>
          <li  v-for="(item, x) in mainNav.navigation" :key='x'><a href="#"  @click.prevent="mainNavigation(item)" :class="{active: item.url === mainNav.active}">{{item.name}}</a></li>
        </ul>
      </nav>
      <div class="navFooter">
        <h2> <a href="tel:+491786966887">+49 178 696 68 87</a></h2>
        <p>Berlin - Oberwil-Lieli - Zürich</p>
      </div>
    </div>




    <div class="headerMobile mobileOnly">
      <div class="mainLogoMobile">
           <img src="../assets/logoMobile_neg.svg">
          </div>
          <div class="mobileMenuBtn" @click="mobileNavFunc">
            <img v-if="mobileNavOpen === false" src="../assets/menu.png" >
            <img  v-if="mobileNavOpen === true" src="../assets/menuClose.png" >
          </div>
    </div>



    <div class="mobileNavigation" v-if="mobileNavOpen">
      
      <nav>
        <ul>
          <li  v-for="(item, x) in mainNav.navigation" :key='x'><a href="#"  @click.prevent="mainNavigation(item)" :class="{active: item.url === mainNav.active}">{{item.name}}</a></li>
         
        </ul>
      </nav>
      <div class="navFooterMobile">
        <h2> <a href="tel:+491786966887">+49 178 696 68 87</a></h2>
        <p>Berlin - Oberwil-Lieli - Zürich</p>
      </div>
     
    </div>


</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      mainNav:{required: true},
      mobileNavOpen:{required: true},
      mainNavigation:{Type: Function},
      mobileNavFunc:{Type: Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
      
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>