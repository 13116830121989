<template>
      <div class="contentInner ">
       
       <h1><span>MPU Completed</span><br />Ihr Weg zur erfolgreichen MPU</h1>
       <p>Willkommen bei MPU Completed, Ihrem kompetenten Partner auf dem Weg zur erfolgreichen MPU. Unser erfahrenes Team aus Verkehrspsychologen und Psychotherapeuten unterstützt Sie professionell und einfühlsam bei der Vorbereitung auf Ihre Medizinisch-Psychologische Untersuchung. Mit unserer hohen Erfolgsquote und langjährigen Expertise begleiten wir Sie sicher durch diesen entscheidenden Schritt zurück auf die Straße.</p>
      </div>
     
      <div class="contentRow">
        <div class="contentColCard">
          <img src="../assets/icn_drogen.svg"  width="80" height="80" />
          <h2>MPU wegen Drogen</h2>
          <p>Haben Sie Probleme im Zusammenhang mit Drogenkonsum und stehen vor einer MPU? Vertrauen Sie auf unsere spezialisierte Beratung und individuelle Unterstützung, um Ihre MPU erfolgreich zu absolvieren und wieder sicher am Straßenverkehr teilzunehmen.</p>
        </div>
        <div class="contentColCard">
          <img src="../assets/icn_alkohol.svg"  width="80" height="80" />
          <h2>MPU wegen Alkohol</h2>
          <p>Alkoholbezogene Verstöße können ernsthafte Konsequenzen haben. Mit unserem erfahrenen Team von Verkehrspsychologen helfen wir Ihnen dabei, Ihr Verhalten zu reflektieren, Ihre Einstellung zu ändern und Ihre MPU mit Zuversicht zu bestehen.</p>
        </div>
      </div>
      <div class="contentRow">
        <div class="contentColCard">
          <img src="../assets/icn_straftaten.svg"  width="80" height="80" />
          <h2>MPU wegen Straftaten</h2>
          <p>Steht Ihnen eine MPU aufgrund von strafrechtlichen Vergehen bevor? Unsere fachkundigen Verkehrspsychologen bieten Ihnen eine umfassende Betreuung und unterstützen Sie dabei, Ihre Vergangenheit zu überwinden und eine positive Zukunft im Straßenverkehr zu gestalten.</p>
        </div>
        <div class="contentColCard">
          <img src="../assets/icn_verkehr.svg"  width="80" height="80" />
          <h2>MPU wegen Verkehrsdelikten</h2>
          <p>Ob Geschwindigkeitsübertretungen, Fahrerflucht oder andere Verkehrsdelikte – wir stehen Ihnen mit unserer Expertise zur Seite, um Ihre MPU vorzubereiten und Ihnen den Weg zurück zur Fahrerlaubnis zu ebnen.</p>
        </div>
      </div>
</template>
<script>
export default {
  name: '${COMPONENT_NAME}',
  components: {
   
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    mainNavigation:{Type: Function}
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      kontakt:{
          name:"Kontakt",
          url:"/kontakt",
        },
        angebot:{
          name:"Angebot",
          url:"/angebot",
        }
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
  },
};
</script>
<style lang="scss">
</style>
