<template>
  <div class="contentInner">
    <h1><span>MPU Completed</span><br />Kontaktieren Sie uns für Ihre<br />MPU-Vorbereitung</h1>
    <p>
      Wir freuen uns darauf, von Ihnen zu hören und Ihnen bei Ihrer MPU-Vorbereitung behilflich zu sein. Über das Kontaktformular können Sie nicht nur Ihre Fragen stellen, sondern auch Terminanfragen für Beratungssitzungen senden. Unser Team steht Ihnen jederzeit zur Verfügung, um Ihnen den bestmöglichen Service zu bieten und Ihnen auf Ihrem Weg zur erfolgreichen MPU zu unterstützen. Zögern Sie nicht, uns zu kontaktieren – wir sind für Sie da!
    </p>
  </div>
    <div  class="contentRow ">
      <div class="contentColForm" style="width:100%">
        <div>
          <label for="name">Name</label>
          <input type="text" id="name" class="formTxt"  v-model="name">
        </div>
        <div>
          <label for="email">E-Mail</label>
          <input type="text" id="email" class="formTxt"  v-model="email">
        </div>
        <div>
          <label for="telefon">Telefon</label>
          <input type="text" id="telefon" class="formTxt"  v-model="telefon">
        </div>
        <div>
          <label for="nachricht">Nachricht</label>
          <textarea id="nachrit" class="formArea"  v-model="nachricht"></textarea>
        </div>
        <div>
          <button @click="submitForm">Absenden</button>
        </div>
      </div>
      </div>

      <div class="formSend" v-if="formStatus">
        <div class="formSendInner">
          <h2>Vielen Dank für Ihre Nachricht</h2>
          <p>Vielen Dank für Ihre Nachricht! Wir haben Ihre Anfrage erhalten und werden uns so schnell wie möglich bei Ihnen melden. Ihr Anliegen ist uns wichtig, und wir werden unser Bestes tun, um Ihnen weiterzuhelfen. Sollten Sie dringende Fragen haben oder sofortige Unterstützung benötigen, zögern Sie nicht, uns telefonisch zu kontaktieren.</p>
          <p> &nbsp;</p>
          <button @click="formStatus=false">Schliessen</button>
        </div>
      </div>
      

     



</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        formStatus:false,
        WEB3FORMS_ACCESS_KEY:"f3dae105-64b2-46fe-b30a-d9f100454269",
        name:"",
        telefon:"",
        email:"",
        nachricht:""
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async submitForm() {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: this.WEB3FORMS_ACCESS_KEY,
            name: this.name,
            telefon: this.telefon, 
            email: this.email,
            nachricht:this.nachricht,            
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(result);
          this.formStatus = true;
        }
      },

    },
  };
</script>
<style lang="scss">


</style>