import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AblaufView from '../views/AblaufView.vue'
import TransformationView from '../views/TransformationView.vue'
import StandorteView from '../views/StandorteView.vue'
import KontaktView from '../views/KontaktView.vue'
import AboutView from '../views/AboutView.vue'
import ErstberatungView from '../views/ErstberatungView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ablauf',
    name: 'ablauf',
    component: AblaufView
  },
  {
    path: '/transformation',
    name: 'transformation',
    component: TransformationView
  },

  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/erstberatung',
    name: 'erstberatung',
    component: ErstberatungView
  },
  {
    path: '/standorte',
    name: 'standorte',
    component: StandorteView
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: KontaktView
  }

]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

export default router
